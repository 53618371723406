.banner-back {
  background-image: url("../images/banner_back.jpg");
  background-position: top center;
  background-repeat: no-repeat;
  width: 100%;
  height: 657px;
}

.phone-left {
  margin-top: 96px;
}

.banner-text {
  text-align: center;
}

.banner-text h1 {
  color: #000;
  font-size: 60px;
  font-weight: 500;
  text-align: center;
  margin-top: 165px;
}

.banner-text h1 span:first-child {
  color: #fff;
  background-color: #000;
  padding: 0 10px 0 10px;
  margin-right: 5px;
}

.banner-text h1 span:last-child {
  font-weight: 800;
}

.banner-text h3 {
  font-family: 'Arimo', sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #000;
  text-transform: uppercase;
  text-align: center;
  margin-top: 10px;
}

.store-icons {
  margin-top: 24px;
}

.store-icons a:first-child {
  margin-right: 10px;
}

.banner-row {
  position: relative;
}

.car-box {
  position: absolute;
  left: 114px;
  bottom: -33px;
}

.desc-icons {
  float: right;
  margin-top: 161px;
}

.desc-icons h4 {
  font-size: 22px;
  color: #9f9e98;
  float: right;
  margin-right: 20px;
}

.desc-icons h4 span {
  font-weight: 700;
}

.desc-icons--left img {
  width: 45px;
  height: 56px;
  position: relative;
  top: -3px;
  float: right;
}

.desc-icons--right {
  position: relative;
}

.desc-icons--right:before {
  content: '';
  position: absolute;
  top: -3px;
  left: 23px;
  width: 1px;
  height: 100%;
  background-color: #ddddda;
}

.desc-icons--right img {
  width: 45px;
  height: 56px;
  position: relative;
  top: -3px;
  left: -3px;
  float: right;
}

.banner-items {
  background-color: #000;
  padding-bottom: 85px !important;
}

.banner-items--head {
  margin: 75px 0;
}

.banner-items--head h2 {
  font-size: 40px;
  color: $yellow;
  text-align: center;
  font-weight: 400;
}

.banner-items--head h2 span {
  color: #fff;
  font-weight: 700;
}

.banner-items--item img {
  width: 33px;
  height: 33px;
  display: block;
}

.banner-items--item h5 {
  font-size: 25px;
  font-weight: 800;
  color: #fff;
  text-transform: uppercase;
  margin-top: 20px;
}

.banner-items--item p {
  font-size: 23px;
  font-weight: 600;
  color: $yellow;
  margin-top: 10px;
  line-height: 26px;
}

.banner-items--item p.banner-items--text {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  margin-top: 6px;
  line-height: 20px;
}

.button-solutions--box {
  text-align: center;
  padding: 60px 0;
}

.button-register {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  background-color: #000;
  padding: 15px 45px;
  border-radius: 50px;
  margin-right: 20px;
}

.button-register:hover {
  color: #fff;
}

.button-solutions {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #000;
  background-color: $yellow;
  padding: 15px 35px;
  border-radius: 50px;
}