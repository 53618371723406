@media (max-width: 1199px) {

  .banner-text h1 span:first-child {
    display: inline-block;
  }

  .phone-left {
    margin-top: 111px;
  }

  .phone-left img {
    width: 250px;
  }

  .car-box {
    left: 24px;
    bottom: -27px;
  }

  .car-box img {
    width: 390px;
  }

  .fleet-cars img {
    width: 421px;
    height: auto;
  }

  .second-car {
    width: 485px !important;
    height: auto !important;
  }

  .contacts-car {
    left: -3px;
  }



}


@media (max-width: 991px) {

  .logo-city img {
    padding-top: 13px;
  }

  .banner-text h1 {
    margin-top: 101px;
  }

  .phone-left {
    margin-top: 64px;
  }

  .car-box {
    display: none;
  }

  .desc-icons {
    float: left;
    margin-top: 81px;
    margin-left: 58px;
  }

  .desc-icons--left {
    text-align: left;
    float: left;
  }

  .desc-icons h4 {
    float: left;
    margin-right: 20px;
  }

  .desc-icons--left img, .desc-icons--right img {
    float: none;
  }

  .desc-icons--right:before {
    left: -64px;
  }

  .banner-items--item p.banner-items--text {
    height: 100px;
  }

  .footer-call p.footer-call--number {
    margin-top: 10px;
  }

  .how-steps--text {
    margin-top: 20px;
    width: 100%;
    margin-right: 0;
    padding-right: 40px;
  }

  .how-steps--text-right {
    margin-top: 20px;
    width: 100%;
  }

  .how-steps--special-right {
    margin-top: 20px;
    width: 100%;
  }

  .steps-row {
    margin-top: 40px;
  }

  .steps-row3 {
    margin-top: 20px;
  }

  .steps-button {
    margin-top: 40px;
  }

  .how-steps--text-right {
    margin-left: 0;
    padding-left: 40px;
  }

  .how-steps--special-right {
    margin-left: 0;
    padding-left: 40px;
  }

  .fleet-cars img {
    width: 321px;
    height: auto;
  }

  .second-car {
    width: 409px !important;
    height: auto !important;
  }

  .cost-list li p:first-of-type {
    width: 73%;
  }

  .parc-logos .col-xs-offset-2 {
    margin-left: 0;
  }

  .parc-logo--in {
    margin-top: 55px;
    margin-left: 103px;
  }

  .contacts-car {
    display: none;
  }

  .contacts-left {
    margin-top: 44px;
  }



}


@media (max-width: 767px) {

  .banner-row {
    display:flex;
    flex-direction: column;
  }

  .banner-text {
    order:-1;
  }

  .banner-text h1 {
    margin-top: 35px;
    font-size: 47px;
  }

  .phone-left img {
    width: 180px;
  }

  .phone-left {
    margin-top: 35px;
    text-align: center;
  }

  .banner-back {
    height: auto;
  }

  .store-icons {
    margin-top: 61px;
    text-align: center;
  }

  .desc-icons {
    margin-top: 48px;
    margin-left: 0;
    margin-bottom: 30px;
  }

  .desc-icons--right {
    margin-top: 36px !important;
  }

  .desc-icons--right:before {
    opacity: 0;
  }

  .desc-icons--left {
    float: none;
    position: relative;
    left: 50%;
    margin-left: -118px !important;
  }

  .desc-icons--left:after {
    content: '';
    position: absolute;
    bottom: -17px;
    left: -14px;
    width: 260px;
    height: 2px;
    background-color: #ddddda;
  }

  .desc-icons--right {
    float: none;
    position: relative;
    left: 50%;
    margin-left: -114px !important;
  }

  .banner-items--head {
    margin: 49px 0;
  }

  .banner-items--head h2 {
    font-size: 30px;
  }

  .banner-items {
    padding-bottom: 27px !important;
  }

  .button-solutions--box {
    padding: 60px 15px;
  }

  .footer-call p.footer-call--number {
    margin-top: 0;
  }

  .footer-lists, .footer-call, .footer-social, .footer-social--in, .footer-apps {
    text-align: center;
  }

  .footer-call img {
    float: none;
    margin-right: 0;
    margin-bottom: 7px;
  }

  .footer-social a {
    display: inline-block;
  }

  .footer-call, .footer-social {
    margin-top: 20px;
  }

  .footer-top {
    padding: 50px 0 40px 0;
  }

  .how-head h1, .how-head h1 span {
    font-size: 48px;
  }

  .how-head h2 {
    font-size: 24px;
  }

  .how-head {
    margin-top: 42px;
  }

  .how-back {
    min-height: auto;
    padding-bottom: 80px;
  }

  .how-car img {
    width: 279px;
    height: auto;
  }

  .how-bottom {
    background-color: #000;
  }

  .how-bottom--left, .how-bottom--right {
    display: none;
  }

  .how-bottom--text {
    margin-bottom: 40px;
  }

  .how-bottom--text-right h3, .how-bottom--text-right p {
    padding-left: 0;
  }

  .empresas-head h3 {
    font-size: 28px;
  }

  .empresas-col h4 {
    font-size: 24px;
  }

  .empresas-col {
    margin-bottom: 50px;
  }

  .fleet-cars img {
    position: relative;
    @include center-x
    margin-left: 0;
  }

  .fleet-cars h4 {
    margin-top: 42px;
  }

  .fleet-cars--right {
    margin-top: 60px;
  }

  .second-car {
    margin-left: 0 !important;
  }

  .button-box--empresas {
    display: table;
  }

  .button-box--empresas .button-register {
    display: table;
    margin: 0 auto 20px auto;
  }

  .button-box--empresas .button-solutions {
    display: table;
    margin: 0 auto;
  }

  .faqs-content {
    padding-left: 15px;
    margin-bottom: 90px;
  }

  .faqs-menu {
    position: relative;
    margin-bottom: 20px;
  }

  .faqs-head h1 {
    font-size: 48px;
  }

  .faqs-back {
    height: 100px;
  }

  .terms-content h2 {
    padding: 20px 30px 5px 0;
  }

  .faqs-menu li a {
    width: 100%;
  }

  .parcerias-head h1 {
    font-size: 48px;
  }

  .parcerias-head h3 {
    font-size: 24px;
  }

  .parcerias-head h4 {
    font-size: 21px;
  }

  .parc-logos--box {
    margin-bottom: 40px;
  }

  .parc-logos {
    margin-top: 60px;
    margin-bottom: 63px;
  }

  .parc-map--text h2 {
    margin-top: 45px;
  }

  .parc-content {
    top: 0;
    margin-top: 16px;
    margin-bottom: 70px;
  }

  .parc-logo--in {
    margin-left: 0;
    text-align: center;
  }

  .contacts-head h1 {
    font-size: 48px;
  }

  .contacts-back {
    min-height: 200px;
  }

  .contacts-row {
    display: flex;
    flex-direction: column;
  }

  .contacts-form {
    order: -1;
  }

  .l_input {
    width: 100%;
  }

  .r_input {
    width: 100%;
    float: left;
  }

  .contacts-left {
    margin-top: 24px;
    margin-bottom: 20px;
  }

  .zona-head h1 {
    font-size: 48px;
    margin-top: 22px
  }



}


@media (max-width: 575px) {

  .logo-city img {
    width: 215px;
    padding-top: 19px;
  }

  .store-icons {
    margin-top: 21px;
  }

  .store-icons img:last-of-type {
    margin-top: 10px;
  }

  .banner-items--item p.banner-items--text {
    height: auto;
  }

  .banner-items--item {
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }

  .banner-items--item img {
    margin: 0 auto;
  }

  .button-solutions--box {
    display: table;
  }

  .button-register {
    display: table;
    margin: 0 auto 20px auto;
  }

  .button-solutions {
    display: table;
    margin: 0 auto;
  }

  .button-solutions--box {
    padding: 32px 15px;
  }

  .how-steps--box, .how-steps--box-right {
    width: 100%
  }

  .middle-bar {
    display: none;
  }

  .how-steps--text {
    padding-right: 0;
  }

  .how-steps--text-right, .how-steps--special-right {
    padding-left: 0;
  }

  .how-steps--special-right, .how-steps--box-right {
    margin-left: 0;
  }

  .steps-row2 {
    margin-top: 50px;
  }

  .steps-row3 {
    margin-top: 63px;
  }

  .steps-row4 {
    margin-top: 51px;
  }

  .step-number--left {
    right: 0;
  }

  .step-number--right {
    left: 0;
  }

  .how-steps--box > img {
    width: 108px;
    height: auto;
    margin-right: 24px;
  }

  .how-steps--box-right > img {
    width: 108px;
    height: auto;
    margin-left: 24px;
  }

  .fleet-cars img {
    width: 100%;
    height: auto;
  }

  .second-car {
    width: 100% !important;
    height: auto !important;
  }

  .fleet-head {
    margin-top: 0;
  }




}