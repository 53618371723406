.pages-back {
  background-image: url("../images/how_back2.jpg");
  background-position: top center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 270px;
  position: relative;
}

.parcerias-head {
  text-align: center;
  margin-top: 70px;
}

.parcerias-head h1 {
  color: #fff;
  font-size: 60px;
  font-weight: 500;
  background-color: #000;
  padding: 0 10px 0 10px;
  display: inline-block;
}

.parcerias-head h3 {
  color: #000;
  font-size: 30px;
  font-weight: 600;
  margin-top: 12px;
}

.parcerias-head h4 {
  color: #000;
  font-size: 24px;
  font-weight: 400;
  margin-top: 10px;
  max-width: 449px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.parc-logos {
  margin-top: 100px;
  margin-bottom: 100px;
}

.parc-logos--box {
  width: 100%;
  height: 204px;
  -webkit-box-shadow: 0px 0px 23px -3px rgba(0,0,0,0.58);
  -moz-box-shadow: 0px 0px 23px -3px rgba(0,0,0,0.58);
  box-shadow: 0px 0px 23px -3px rgba(0,0,0,0.58);
  display: flex;
  align-items: center;
  justify-content: center;
}

.parc-logos--box img {
  width: 187px;
  height: 44px;
}

.parc-logos--box2 {
  width: 100%;
  height: 204px;
  -webkit-box-shadow: 0px 0px 23px -3px rgba(0,0,0,0.58);
  -moz-box-shadow: 0px 0px 23px -3px rgba(0,0,0,0.58);
  box-shadow: 0px 0px 23px -3px rgba(0,0,0,0.58);
  display: flex;
  align-items: center;
  justify-content: center;
}

.parc-logos--box2 img {
  width: 171px;
  height: 67px;
}

.partner-banner {
  background-image: url("../images/partner_banner.jpg");
  background-position: top center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 320px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.partner-banner--text {
  text-align: center;
}

.partner-banner--text h4 {
  color: #fff;
  font-weight: 400;
  font-size: 40px;
}

.partner-banner--text h4 span {
  font-weight: 700;
}

.partner-banner--text p {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
  display: inline-block;
}

.button-back a {
  color: #000;
  font-weight: 600;
}

.parc-logo--in {
  margin-top: 55px;
  margin-left: 220px;
}

.parc-logo--in img {
  width: 284px;
  height: 82px;
}

.parc-content {
  position: relative;
  top: 16px;
  margin-bottom: 110px;
}

.parc-map {
  margin-top: 50px;
}

.google-maps {
  position: relative;
  padding-bottom: 55%; // This is the aspect ratio
  height: 0;
  overflow: hidden;
}

.google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.parc-map--text h2 {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  line-height: 31px;
  margin-top: 65px;
}

.parc-map--text h2 span {
  font-weight: 700;
}

.parc-map--text p {
  font-weight: 400;
  color: #000;
  margin-top: 30px;
  line-height: 25px;
}

.how-back {
  background-image: url("../images/how_back.jpg");
  background-position: top center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 270px;
  position: relative;
}

.how-head {
  text-align: center;
  margin-top: 70px;
}

.how-head h1 {
  color: #000;
  font-size: 60px;
  font-weight: 700;
  background-color: transparent;
  padding: 0;
  display: inline-block;
}

.how-head h1 span {
  color: #fff;
  font-size: 60px;
  font-weight: 500;
  background-color: #000;
  padding: 0 10px 0 10px;
  display: inline-block;
}

.how-head h2 {
  color: #000;
  font-size: 30px;
  font-weight: 400;
  margin-top: 10px;
}

.how-head h2 span {
  font-weight: 600;
}

.how-car {
  text-align: center;
  position: relative;
  top: -61px;
}

.how-car img {
  width: 461px;
  height: 191px;
}

.how-steps {
  position: relative;
  margin-bottom: 100px;
  margin-top: 105px;
}

.steps-row {
  margin-top: 150px;
}

.steps-row2 {
  margin-top: 20px;
}

.steps-row3 {
  margin-top: 140px;
}

.steps-row4 {
  margin-top: 0;
}

.middle-bar {
  width: 3px;
  height: 100%;
  background-image: linear-gradient(#8d8d8b 33%, rgba(255, 255, 255, 0) 0%);
  background-size: 3px 10px;
  background-repeat: repeat-y;
  position: absolute;
  top: 0;
  @include center-x
  z-index: 0;
}

.how-steps--box {
  position: relative;
  top: -50px;
}

.how-steps--text {
  float: right;
  text-align: right;
  margin-top: 10px;
  margin-right: 40px;
  width: 50%;
}

.how-steps--text h2 {
  color: #000;
  font-size: 30px;
  font-weight: 400;
}

.how-steps--text h2 span {
  font-weight: 700;
}

.how-steps--text p:first-of-type {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  margin-top: 5px;
  display: block;
  line-height: 22px;
}

.how-steps--text p:last-of-type {
  font-size: 16px;
  color: #000;
  font-weight: 400;
  margin-top: 5px;
}

.how-steps--box > img {
  width: 128px;
  height: 128px;
  float: right;
}

.step-number--left {
  float: right;
  width: 58px;
  height: 48px;
  position: relative;
  top: 50px;
  right: -39px;
  z-index: 1;
}

.step-number--left img {
  width: 60px;
  height: 48px;
  position: absolute;
  top: 0;
  left: -2px;
}

.step-number--left p {
  font-size: 30px;
  font-weight: 500;
  color: #fff;
  position: absolute;
  top: 11px;
  left: 26px;
}

.how-steps--box-right {
  position: relative;
  top: -50px;
}

.how-steps--box-right > img {
  width: 128px;
  height: 128px;
  float: left;
}

.step-number--right {
  float: left;
  width: 58px;
  height: 48px;
  position: relative;
  top: 36px;
  left: -39px;
  z-index: 1;
}

.step-number--right img {
  width: 58px;
  height: 48px;
  position: absolute;
  top: 0;
  right: 0;
}

.step-number--right p {
  font-size: 30px;
  font-weight: 500;
  color: #fff;
  position: absolute;
  top: 11px;
  right: 26px;
}

.how-steps--text-right {
  float: left;
  text-align: left;
  margin-top: 10px;
  margin-left: 40px;
  width: 50%;
}

.how-steps--text-right h2 {
  color: #000;
  font-size: 30px;
  font-weight: 400;
}

.how-steps--text-right h2 span {
  font-weight: 700;
}

.how-steps--text-right p:first-of-type {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  margin-top: 5px;
  display: block;
  line-height: 22px;
}

.how-steps--text-right p:last-of-type {
  font-size: 16px;
  color: #000;
  font-weight: 400;
  margin-top: 5px;
}

.how-steps--special-right {
  float: left;
  text-align: left;
  margin-top: 10px;
  margin-left: 40px;
  width: 50%;
}

.how-steps--special-right h2 {
  color: #000;
  font-size: 30px;
  font-weight: 400;
}

.how-steps--special-right h2 span {
  font-weight: 700;
}

.how-steps--special-right p:nth-of-type(1), .how-steps--special-right p:nth-of-type(3) {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  margin-top: 5px;
  display: block;
  line-height: 22px;
}

.how-steps--special-right p:nth-of-type(2), .how-steps--special-right p:nth-of-type(4) {
  font-size: 16px;
  color: #000;
  font-weight: 400;
  margin-top: 5px;
}

.how-bottom {
  position: relative;
  padding: 40px 0;
}

.how-bottom--left {
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #000;
}

.how-bottom--right {
  position: absolute;
  width: 50%;
  height: 100%;
  right: 0;
  top: 0;
  background-color: #171717;
}

.how-bottom--text h3 {
  font-size: 30px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.how-bottom--text p {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  line-height: 25px;
  padding-right: 30px;
}

.how-bottom--text-right h3 {
  font-size: 30px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-left: 30px;
}

.how-bottom--text-right p {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  line-height: 25px;
  padding-left: 30px;
}

.steps-button {
  text-align: center;
}

.steps-button--register {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  background-color: #000;
  padding: 15px 45px;
  border-radius: 50px;
}

.steps-button--register:hover {
  color: #fff;
}

.fleet-head {
  text-align: center;
  margin-top: 30px;
}

.fleet-head h3 {
  font-size: 48px;
  font-weight: 400;
  color: #000;
}

.fleet-head h3 span {
  font-weight: 700;
}

.fleet-head p {
  font-size: 20px;
  font-weight: 400;
  color: #000;
  margin-top: 3px;
}

.fleet-row {
  margin-top: 60px;
  margin-bottom: 110px;
}

.first-car {
  width: 461px;
  height: auto;
  margin-left: 20px;
  margin-top: -29px;
}

.second-car {
  width: 513px !important;
  height: 286px !important;
  margin-left: 20px !important;
  position: relative;
  top: -30px;
}

.fleet-cars--left h4 {
  font-size: 30px;
  font-weight: 700;
  color: #000;
  margin-top: 33px;
}

.fleet-cars--right h4 {
  font-size: 30px;
  font-weight: 700;
  color: #000;
  margin-top: -5px;
}

.fleet-cars ul {
  list-style: none;
  margin: 25px 0;
}

.fleet-cars li {
  width: 100%;
  font-size: 16px;
  font-weight: 800;
  text-transform: uppercase;
  color: #000;
  border-bottom: 5px solid $yellow;
  padding-bottom: 10px;
  margin-bottom: 10px;
  line-height: 23px;
}

.fleet-cars li span {
  font-weight: 600;
}

.cost-back {
  background-color: #000;
  padding: 40px 0;
  position: relative;
}

.cost-head h5 {
  font-size: 30px;
  color: $yellow;
  font-weight: 400;
  background-color: #000;
  display: inline-block;
  padding: 10px 15px;
  position: absolute;
  top: -93px;
  left: 0;
}

.cost-head h5 span {
  font-weight: 700;
}

.cost-list ul {
  list-style: none;
}

.cost-list li {
  width: 100%;
  display: inline-block;
  margin-bottom: 6px;
  border-bottom: 3px solid #1c1c1a;
  padding-bottom: 10px;
}

.cost-list li p:first-of-type {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  float: left;
  width: 59%;
  line-height: 25px;
}

.cost-list li p:last-of-type {
  font-size: 16px;
  color: $yellow;
  font-weight: 600;
  float: right;
  text-align: right;
}

.faqs-back {
  background-color: $yellow;
  height: 130px;
}

.faqs-head {
  text-align: center;
  position: relative;
  top: -35px;
}

.faqs-head h1 {
  color: #fff;
  font-size: 60px;
  font-weight: 500;
  background-color: #000;
  padding: 0 10px 0 10px;
  display: inline-block;
}

.faqs-menu {
  position: sticky;
  top: 10px;
}

.faqs-menu ul {
  list-style: none;
}

.faqs-menu li {
  display: inline-block;
  width: 100%;
  border-bottom: 5px solid $yellow;
}

.faqs-menu li a {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  padding: 10px 0;
  display: inline-block;
  line-height: 23px;
}

.faqs-content {
  padding-left: 30px;
  margin-bottom: 90px;
}

.faqs-content h2 {
  font-size: 28px;
  color: #000;
  font-weight: 700;
  border-bottom: 5px solid #ececeb;
  display: inline-block;
  padding: 40px 30px 5px 0;
  line-height: 34px;
  margin-bottom: 8px;
}

.faqs-content ul {
  list-style: none;
  margin-top: 5px;
  margin-bottom: 10px;
}

.faqs-content li {
  border-bottom: 2px dotted #999595;
  padding: 15px 0;
}

.faqs-content li a {
  font-size: 16px;
  color: #000;
  font-weight: 700;
}

.faqs-content li p {
  padding-top: 15px;
  font-weight: 500;
  display: none;
  line-height: 23px;
}

.faqs-content ul:first-of-type li:first-of-type p {
  display: block;
}

.terms-content {
  margin-bottom: 90px;
}

.terms-content h2 {
  font-size: 28px;
  color: #000;
  font-weight: 700;
  border-bottom: 5px solid #ececeb;
  display: inline-block;
  padding: 40px 30px 5px 0;
  line-height: 34px;
  margin-bottom: 18px;
}

.terms-content h3 {
  font-size: 16px;
  color: #000;
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 20px;
  line-height: 23px;
}

.terms-content p {
  padding-top: 15px;
  font-weight: 500;
  line-height: 23px;
}

.contacts-back {
  background-image: url("../images/how_back2.jpg");
  background-position: top center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contacts-cont {
  position: relative;
}

.contacts-head {
  text-align: center;
}

.contacts-head h1 {
  color: #fff;
  font-size: 60px;
  font-weight: 500;
  background-color: #000;
  padding: 0 10px 0 10px;
  display: inline-block;
}

.contacts-car {
  position: absolute;
  left: 36px;
  top: -79px;
}

.contacts-car img {
  width: 397px;
  height: 352px;
}

.contacts-left {
  margin-top: 140px;
}

.contacts-left p {
  color: #000;
  font-weight: 500;
  margin-bottom: 20px;
}

.contacts-left p span {
  font-weight: 700;
}

.contacts-form {
  margin-top: 40px;
  margin-bottom: 10px;
}

.contacts-form h2 {
  font-size: 24px;
  font-weight: 700;
  color: #000;
}

.contacts-form form {
  margin-top: 40px;
}

.l_input {
  width: 50%;
  float: left;
}

.r_input {
  width: 47%;
  float: right;
}

.b_input {
  width: 100%;
  float: left;
}

.contacts-form input {
  margin-bottom: 20px;
  outline: 0;
  border: 0;
  border-bottom: 2px solid #c8c5c5;
  padding-bottom: 10px;
  color: #000;
  font-weight: 600;
}

.contacts-form textarea {
  width: 100%;
  height: 83px;
  outline: 0;
  border: 0;
  border-bottom: 2px solid #c8c5c5;
  resize: none;
  padding-bottom: 10px;
  color: #000;
  font-weight: 600;
}

.button-form {
  font-size: 14px;
  font-weight: 700 !important;
  text-transform: uppercase;
  color: #fff !important;
  background-color: #000;
  padding: 15px 45px !important;
  border-radius: 50px;
  float: right;
  margin-top: 30px;
  border-bottom: none !important;
}

.button-form:hover {
  color: #fff;
}

.empresas-head {
  text-align: center;
  margin-top: 30px;
}

.empresas-head h3 {
  font-size: 36px;
  font-weight: 700;
  color: #000;
}

.empresas-row {
  margin-top: 70px;
  margin-bottom: 50px;
}

.empresas-col {
  text-align: center;
}

.empresas-col h4 {
  color: #000;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
}

.empresas-col svg {
  height: 163px;
  width: auto;
  margin: 40px 0 30px 0;
}

.empresas-col p {
  text-align: left;
  font-size: 16px;
  color: #000;
  font-weight: 700;
  line-height: 23px;
}

.empresas-col p:last-of-type {
  font-weight: 400;
}

.zona-head {
  text-align: center;
  position: relative;
  top: -99px;
}

.zona-head h1 {
  color: #fff;
  font-size: 60px;
  font-weight: 500;
  background-color: #000;
  padding: 0 10px 0 10px;
  display: inline-block;
}

.zona-iframe {
  position: relative;
  margin-top: -173px;
  z-index: 1;
  border: 0;
  outline: 0;
}

.or-header--mob {
  background-color: #fff;
}

.zona-wrapper {
  position: relative;
  z-index: 2;
}

.iva {
  width: 100%;
  text-align: center;
  margin-top: 15px;
}

.iva p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  opacity: .7;
}