@import "../css/bootstrap.min.css";
@import url('https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800');
@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700');
@import url('https://fonts.googleapis.com/css?family=Arimo:700');

@import "base/global";
@import "base/variaveis";

@import "helpers/mixins";

@import "layout/header";
@import "layout/footer";

@import "pages/homepage";
@import "pages/pages";
@import "pages/anim";

@import "responsive/responsive";