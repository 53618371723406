.top-header--back {
  background-color: #000;
  position: relative;
}

.button-login--box {
  margin-top: 22px;
  margin-bottom: 23px;
  text-align: right;
}

.button-login {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: #000;
  background-color: $yellow;
  padding: 10px 25px;
  border-radius: 50px;
  margin-right: 5px;
}

.button-client {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: #000;
  background-color: #fff;
  padding: 10px 25px;
  border-radius: 50px;
}

.header--back {
  -webkit-box-shadow: 0px 5px 8px -2px rgba(0,0,0,0.20);
  -moz-box-shadow: 0px 5px 8px -2px rgba(0,0,0,0.20);
  box-shadow: 0px 5px 8px -2px rgba(0,0,0,0.20);
  position: relative;
  z-index: 2;
}

.header-nav {
  text-align: right;
}

.header-nav ul {
  list-style: none;
}

.header-nav li {
  display: inline-block;
  position: relative;
}

.header-nav li:after {
  content: '';
  left: 0;
  right: 0;
  position: absolute;
  height: 2px;
  bottom: 0;
  margin-right: 0;
  margin-left: 25px;
  background-color: #000;
  opacity: 0;
  @include transition
}

.header-nav li:hover:after {
  opacity: 1;
}

.header-nav li a {
  font-family: 'PT Sans', sans-serif;
  font-size: 15px;
  font-weight: 700;
  color: #000;
  text-transform: uppercase;
  padding: 10px 0 10px 25px;
  display: inline-block;
}

.header-nav li.active {
  display: inline-block;
  position: relative;
}

.header-nav li.active:after {
  content: '';
  left: 0;
  right: 0;
  position: absolute;
  height: 2px;
  bottom: 0;
  margin-right: 0;
  margin-left: 25px;
  opacity: 1;
  background-color: #000;
}

.logo-city img {
  width: 325px;
  height: auto;
  padding-top: 10px;
}

.mob-login--box {
  margin-top: 15px;
  margin-bottom: 16px;
  text-align: center;
}

.mob-login {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: #000;
  background-color: $yellow;
  padding: 10px 25px;
  border-radius: 50px;
  margin-right: 5px;
}

.mob-client {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  background-color: #000;
  padding: 10px 25px;
  border-radius: 50px;
}

.mob-client:hover {
  color: #fff;
}

.button-hamb {
  height: 72px;
}

.button-hamb img {
  width: 26px;
  position: absolute;
  top: 26px;
  right: 15px;
}

.mob-nav {
  position: absolute;
  top: 0;
  right: -300px;
  width: 245px;
  height: 100%;
  background-color: #000;
  z-index: 999;
  @include transition3;
}

.mob-nav--show {
  right: 0;
}

.mob-nav ul {
  list-style: none;
  margin-top: 11px;
}

.mob-nav li {
  border-bottom: 1px solid rgba(255,255,255,.1)
}

.mob-nav li a {
  font-weight: 500;
  color: #fff;
  padding: 15px;
  text-transform: uppercase;
  display: inline-block;
  width: 100%;
}

.close-mob {
  position: absolute;
  top: 0;
  right: 245px;
  background-color: #000;
  padding: 13px;
}

.close-mob img {
  width: 25px;
  margin-top: 8px;
}