.or-anim--left {
  opacity: 0;
}

.or-anim--right {
  opacity: 0;
}

.come-in--l {
  transform: translateX(-150px);
  animation: come-in--l 0.8s ease forwards;
  opacity: 0;
  position: relative;
  z-index: 2;
}

.already-visible--l {
  transform: translateX(-150px);
  animation: come-in--l 0.8s ease forwards;
  opacity: 0;
  position: relative;
  z-index: 2;
}

@keyframes come-in--l {
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.come-in--r {
  transform: translateX(150px);
  animation: come-in--r 0.8s ease forwards;
  opacity: 0;
  position: relative;
  z-index: 2;
}

.already-visible--r {
  transform: translateX(150px);
  animation: come-in--r 0.8s ease forwards;
  opacity: 0;
  position: relative;
  z-index: 2;
}

@keyframes come-in--r {
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.come-in--d {
  transform: translateY(150px);
  animation: come-in--d 0.8s ease forwards;
  opacity: 0;
  position: relative;
  z-index: 2;
}

.already-visible--d {
  transform: translateY(150px);
  animation: come-in--d 0.8s ease forwards;
  opacity: 0;
  position: relative;
  z-index: 2;
}

@keyframes come-in--d {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}