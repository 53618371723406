@mixin transition() {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

@mixin transition2() {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@mixin transition3() {
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

@mixin center() {
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

@mixin center-x() {
    left: 50%;
    transform: translateX(-50%);
}

@mixin center-y() {
    top: 50%;
    transform: translateY(-50%);
}