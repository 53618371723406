body {
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, p {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}

.no-padding {
  padding: 0 !important;
  margin: 0 !important;
}

.container {
  min-width: 300px !important;
}

.body-fixed {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  z-index: 1;
}