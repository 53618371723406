.footer-top {
  background-color: $yellow;
  padding: 50px 0 20px 0;
}

.footer-lists ul {
  list-style: none;
}

.footer-lists a {
  font-weight: 600;
  color: #000;
  display: inline-block;
  padding-bottom: 10px;
}

.footer-call img {
  width: 45px;
  height: 45px;
  float: left;
  margin-right: 10px;
}

.footer-call p {
  font-weight: 600;
  color: #000;
  margin-bottom: 3px;
}

.footer-call p.footer-call--number {
  font-weight: 700;
  font-size: 24px;
}

.footer-bottom {
  padding: 27px 15px;
  text-align: center;
}

.footer-bottom p {
  color: #7f7d7d;
}

.footer-social--in {
  width: 100%;
  display: inline-block;
  margin-bottom: 10px;
}

.footer-social--in img {
  width: 32px;
  height: 32px;
  float: left;
  margin-right: 7px;
}

.footer-apps {
  width: 100%;
}

.footer-apps img {
  width: 87px;
  height: 30px;
  float: left;
  margin-right: 7px;
}